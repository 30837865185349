import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ClubPage.css';
import { useAuth } from '../AuthContext';
import { formatDistanceToNow } from 'date-fns';
import '../styles/SharedStyles.css';
import { checkIsFollowingClub, followClub, unfollowClub, getClubFollowerCount } from '../services/clubFollowService';

const defaultUserPhoto = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";
const defaultClubPhoto = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultclub%2Fclub_default.png?alt=media&token=be631b3a-b250-45f0-a683-c1760dd9d96a";

const ClubPage = () => {
    const { clubId } = useParams();
    const [club, setClub] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [creatorUsername, setCreatorUsername] = useState('');
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const { user } = useAuth();
    const [isFollowing, setIsFollowing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [followerCount, setFollowerCount] = useState(0);

    useEffect(() => {
        const fetchClub = async () => {
            try {
                const q = query(
                    collection(db, 'clubs'),
                    where('accountIdLower', '==', clubId.toLowerCase())
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const clubDoc = querySnapshot.docs[0];
                    const clubData = {
                        id: clubDoc.id,
                        ...clubDoc.data()
                    };

                    // Check if current user is admin
                    setIsAdmin(user?.uid === clubData.clubAdmin);

                    // Fetch creator's username
                    if (clubData.createdBy) {
                        const creatorDoc = await getDoc(doc(db, 'users', clubData.createdBy));
                        if (creatorDoc.exists()) {
                            setCreatorUsername(creatorDoc.data().userProfile.username);
                        }
                    }

                    if (clubData.clubAdmin) {
                        const adminDoc = await getDoc(doc(db, 'users', clubData.clubAdmin));
                        if (adminDoc.exists()) {
                            clubData.adminUsername = adminDoc.data().userProfile.username;
                            clubData.adminProfileImage = adminDoc.data().userProfile.profileImageUrl;
                        }
                    }

                    setClub(clubData);
                } else {
                    setError('Club not found');
                }
            } catch (err) {
                console.error('Error loading club:', err);
                setError('Error loading club: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchClub();
    }, [clubId, user]);

    useEffect(() => {
        const checkFollowStatus = async () => {
            if (isAuthenticated && user && club) {
                try {
                    const following = await checkIsFollowingClub(user.uid, club.id);
                    setIsFollowing(following);
                } catch (error) {
                    console.error('Error checking club follow status:', error);
                }
            }
        };

        checkFollowStatus();
    }, [isAuthenticated, user, club]);

    useEffect(() => {
        const fetchFollowerCount = async () => {
            if (club) {
                try {
                    const count = await getClubFollowerCount(club.id);
                    setFollowerCount(count);
                } catch (error) {
                    console.error('Error fetching club follower count:', error);
                }
            }
        };

        fetchFollowerCount();
    }, [club]);

    const getButtonText = () => {
        if (!isFollowing) return 'Follow';
        return isHovering ? 'Unfollow' : 'Following';
    };

    const handleFollow = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            if (isFollowing) {
                await unfollowClub(user.uid, club.id);
                setFollowerCount(prev => prev - 1);
            } else {
                await followClub(user.uid, club.id);
                setFollowerCount(prev => prev + 1);
            }
            setIsFollowing(!isFollowing);
        } catch (error) {
            console.error('Error updating club follow status:', error);
        }
    };

    if (loading) {
        return (
            <>
                <Header />
                <main>
                    <div className="page-container">
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading club...</div>
                        </div>
                    </div>
                </main>
                <Footer />
            </>
        );
    }

    if (error) {
        return (
            <>
                <Header />
                <main>
                    <div className="page-container">
                        <div className="error-message">{error}</div>
                    </div>
                </main>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="page-header">
                        <div className="title-section">
                            <h1>{club.clubName}</h1>
                            <div className="username-display">club/{clubId}</div>
                        </div>
                        {isAdmin && (
                            <button 
                                className="button-secondary"
                                onClick={() => navigate(`/club/${clubId}/edit`)}
                            >
                                Edit Club
                            </button>
                        )}
                    </div>

                    <div className="club-profile-layout">
                        {/* Left Column - Club Image */}
                        <div className="club-sidebar">
                            <div className="club-image-container">
                                <img 
                                    src={club.profileImageUrl || defaultClubPhoto} 
                                    alt={club.clubName} 
                                    className="club-image"
                                />
                            </div>
                            <div className="follow-stats">
                                <Link to={`/club/${clubId}/followers`} className="follow-stat">
                                    <span className="follow-count">{followerCount}</span>
                                    <span className="follow-label">Followers</span>
                                </Link>
                            </div>
                            {isAuthenticated && user?.uid !== club.clubAdmin && (
                                <button 
                                    className={`follow-button ${isFollowing ? 'is-following' : ''}`}
                                    onClick={handleFollow}
                                    onMouseEnter={() => setIsHovering(true)}
                                    onMouseLeave={() => setIsHovering(false)}
                                >
                                    {getButtonText()}
                                </button>
                            )}
                            <div className="club-creation-info">
                                <div className="club-info-section">
                                    Created {formatDistanceToNow(club.createdAt?.toDate())} ago by{' '}
                                    <Link to={`/user/${creatorUsername}`} className="club-creator-link">
                                        {creatorUsername}
                                    </Link>
                                </div>
                                <div className="club-info-divider"></div>
                                <div className="info-section">
                                    <div className="admin-label">Admin</div>
                                    <div className="admin-info">
                                        <img 
                                            src={club.adminProfileImage || defaultClubPhoto} 
                                            alt={`${club.adminUsername}'s profile`} 
                                            className="admin-image"
                                        />
                                        <Link to={`/user/${club.adminUsername}`} className="creator-link">
                                            {club.adminUsername}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Club Information */}
                        <div className="club-content">
                            <h1>{club.clubName}</h1>
                            
                            {club.description && (
                                <section className="club-section">
                                    <h2>Description</h2>
                                    <div className="club-description">
                                        {club.description}
                                    </div>
                                </section>
                            )}

                            <section className="club-section">
                                <h2>Location</h2>
                                <div className="info-grid">
                                    <div className="info-row">
                                        <span className="info-label">Address:</span>
                                        <span className="info-value">{club.location}</span>
                                    </div>
                                    <div className="info-row">
                                        <span className="info-label">City:</span>
                                        <span className="info-value">{club.city}</span>
                                    </div>
                                    <div className="info-row">
                                        <span className="info-label">State:</span>
                                        <span className="info-value">{club.state}</span>
                                    </div>
                                </div>
                            </section>

                            {(club.phone || club.email || club.website) && (
                                <section className="club-section">
                                    <h2>Contact Information</h2>
                                    <div className="info-grid">
                                        {club.phone && (
                                            <div className="info-row">
                                                <span className="info-label">Phone:</span>
                                                <span className="info-value">
                                                    <a href={`tel:${club.phone}`}>{club.phone}</a>
                                                </span>
                                            </div>
                                        )}
                                        {club.email && (
                                            <div className="info-row">
                                                <span className="info-label">Email:</span>
                                                <span className="info-value">
                                                    <a href={`mailto:${club.email}`}>{club.email}</a>
                                                </span>
                                            </div>
                                        )}
                                        {club.website && (
                                            <div className="info-row">
                                                <span className="info-label">Website:</span>
                                                <span className="info-value">
                                                    <a href={club.website} target="_blank" rel="noopener noreferrer">
                                                        {club.website}
                                                    </a>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            )}

                            <section className="club-section">
                                <h2>Weapons</h2>
                                <div className="weapons-tags">
                                    {club.weapons.foil && <span className="weapon-tag">Foil</span>}
                                    {club.weapons.epee && <span className="weapon-tag">Epee</span>}
                                    {club.weapons.saber && <span className="weapon-tag">Saber</span>}
                                </div>
                            </section>

                            {club.coaches && (
                                <section className="club-section">
                                    <h2>Coaches</h2>
                                    <div className="coaches-content">
                                        {club.coaches}
                                    </div>
                                </section>
                            )}

                            {club.membershipFees && (
                                <section className="club-section">
                                    <h2>Membership Fees</h2>
                                    <div className="fees-content">
                                        {club.membershipFees}
                                    </div>
                                </section>
                            )}

                            {club.classSchedule && (
                                <section className="club-section">
                                    <h2>Class Schedule</h2>
                                    <div className="schedule-content">
                                        {club.classSchedule}
                                    </div>
                                </section>
                            )}

                            {club.facilities && (
                                <section className="club-section">
                                    <h2>Facilities</h2>
                                    <div className="facilities-content">
                                        {club.facilities}
                                    </div>
                                </section>
                            )}

                            {club.achievements && (
                                <section className="club-section">
                                    <h2>Achievements</h2>
                                    <div className="achievements-content">
                                        {club.achievements}
                                    </div>
                                </section>
                            )}

                            {(club.facebookPage || club.twitterPage || club.instagramPage) && (
                                <section className="club-section">
                                    <h2>Social Media</h2>
                                    <div className="social-links">
                                        {club.facebookPage && (
                                            <div className="social-link-row">
                                                <a href={club.facebookPage} target="_blank" rel="noopener noreferrer">
                                                    Facebook
                                                </a>
                                            </div>
                                        )}
                                        {club.twitterPage && (
                                            <div className="social-link-row">
                                                <a href={club.twitterPage} target="_blank" rel="noopener noreferrer">
                                                    Twitter
                                                </a>
                                            </div>
                                        )}
                                        {club.instagramPage && (
                                            <div className="social-link-row">
                                                <a href={club.instagramPage} target="_blank" rel="noopener noreferrer">
                                                    Instagram
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            )}

                            <section className="club-section">
                                <h2>Club Details</h2>
                                <div className="info-grid">
                                    {club.clubOwner && (
                                        <div className="info-row">
                                            <span className="info-label">Club Owner:</span>
                                            <span className="info-value">{club.clubOwner}</span>
                                        </div>
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ClubPage;
