import { signInWithPopup, sendSignInLinkToEmail } from 'firebase/auth';
import { auth, googleProvider } from './firebase';
import { FacebookAuthProvider } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';

// Google Sign In
export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        
        // Check if this is a new user or existing user
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        
        if (!userDoc.exists()) {
            // New user - create initial document
            await setDoc(doc(db, 'users', user.uid), {
                email: user.email,
                createdAt: new Date(),
                userProfile: {
                    // Leave username empty to force setup
                    username: '',
                    firstName: user.displayName?.split(' ')[0] || '',
                    lastName: user.displayName?.split(' ').slice(1).join(' ') || ''
                }
            });
            
            // Redirect to profile setup
            window.location.href = '/settings/profile';
        } else {
            // Existing user - check if they have a username
            const userData = userDoc.data();
            if (!userData.userProfile?.username) {
                // No username set - redirect to profile setup
                window.location.href = '/settings/profile';
            }
            // If they have a username, normal login flow continues
        }
    } catch (error) {
        console.error('Error during Google sign in:', error);
        throw error;
    }
};

// Passwordless Email Sign In
export const sendEmailLink = async (email) => {
    const actionCodeSettings = {
        url: window.location.origin, // or your specific redirect URL
        handleCodeInApp: true
    };

    try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
        throw error;
    }
};

export const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
        return result.user;
    } catch (error) {
        throw error;
    }
};
