import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './FollowersPage.css'; // Reuse the same CSS
import { Link } from 'react-router-dom';
import { 
    followUser, 
    unfollowUser, 
    isFollowing as checkIsFollowing 
} from '../services/followService';

const ClubFollowersPage = () => {
    const { clubId } = useParams();
    const { user, isAuthenticated } = useAuth();
    const [club, setClub] = useState(null);
    const [followers, setFollowers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [followStatus, setFollowStatus] = useState({});
    const [hoverStatus, setHoverStatus] = useState({});
    const navigate = useNavigate();

    const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media";

    useEffect(() => {
        const fetchFollowers = async () => {
            try {
                // First fetch the club
                const clubQuery = query(
                    collection(db, 'clubs'),
                    where('accountIdLower', '==', clubId.toLowerCase())
                );
                const clubSnapshot = await getDocs(clubQuery);

                if (!clubSnapshot.empty) {
                    const clubData = {
                        id: clubSnapshot.docs[0].id,
                        ...clubSnapshot.docs[0].data()
                    };
                    setClub(clubData);

                    // Fetch followers
                    const followsRef = collection(db, 'clubFollows');
                    const q = query(followsRef, where('clubId', '==', clubData.id));
                    const querySnapshot = await getDocs(q);
                    
                    // Get follower details
                    const followerPromises = querySnapshot.docs.map(async (docSnapshot) => {
                        try {
                            const followerDoc = await getDoc(doc(db, 'users', docSnapshot.data().followerId));
                            if (!followerDoc.exists()) {
                                console.log('No user document found for ID:', docSnapshot.data().followerId);
                                return null;
                            }
                            return {
                                id: docSnapshot.data().followerId,
                                ...followerDoc.data().userProfile
                            };
                        } catch (err) {
                            console.error('Error fetching user document:', err);
                            return null;
                        }
                    });

                    const followerDetails = (await Promise.all(followerPromises)).filter(Boolean);
                    setFollowers(followerDetails);
                } else {
                    setError('Club not found');
                }
            } catch (error) {
                console.error('Error fetching club followers:', error);
                setError('Error loading followers');
            } finally {
                setLoading(false);
            }
        };

        fetchFollowers();
    }, [clubId]);

    useEffect(() => {
        const checkFollowStatuses = async () => {
            if (isAuthenticated && user && followers.length > 0) {
                const statuses = {};
                for (const follower of followers) {
                    try {
                        const isFollowing = await checkIsFollowing(user.uid, follower.id);
                        statuses[follower.id] = isFollowing;
                    } catch (error) {
                        console.error('Error checking follow status:', error);
                    }
                }
                setFollowStatus(statuses);
            }
        };

        checkFollowStatuses();
    }, [followers, isAuthenticated, user]);

    const handleFollowToggle = async (followerId) => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            const currentlyFollowing = followStatus[followerId];
            if (currentlyFollowing) {
                await unfollowUser(user.uid, followerId);
            } else {
                await followUser(user.uid, followerId);
            }
            setFollowStatus(prev => ({
                ...prev,
                [followerId]: !currentlyFollowing
            }));
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <h1>{club ? `${club.clubName}'s Followers` : 'Club Followers'}</h1>
                    {loading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading...</div>
                        </div>
                    ) : error ? (
                        <div className="error-message">{error}</div>
                    ) : (
                        <div className="followers-list">
                            {followers.length === 0 ? (
                                <div className="no-followers">
                                    This club doesn't have any followers yet
                                </div>
                            ) : (
                                followers.map(follower => (
                                    <div key={follower.id} className="follower-item">
                                        <Link to={`/user/${follower.username}`} className="follower-avatar">
                                            <img 
                                                src={follower.profileImageUrl || defaultAvatar} 
                                                alt={follower.username}
                                                className="avatar-image"
                                            />
                                        </Link>
                                        <div className="follower-details">
                                            <Link to={`/user/${follower.username}`} className="follower-username">
                                                {follower.username}
                                            </Link>
                                            {follower.name && (
                                                <div className="follower-name">{follower.name}</div>
                                            )}
                                        </div>
                                        {user?.uid !== follower.id && (
                                            isAuthenticated ? (
                                                <button 
                                                    className={`follow-button ${followStatus[follower.id] ? 'is-following' : ''}`}
                                                    onClick={() => handleFollowToggle(follower.id)}
                                                    onMouseEnter={() => setHoverStatus(prev => ({ ...prev, [follower.id]: true }))}
                                                    onMouseLeave={() => setHoverStatus(prev => ({ ...prev, [follower.id]: false }))}
                                                >
                                                    {followStatus[follower.id] 
                                                        ? (hoverStatus[follower.id] ? 'Unfollow' : 'Following')
                                                        : 'Follow'}
                                                </button>
                                            ) : (
                                                <button 
                                                    className="follow-button"
                                                    onClick={() => navigate('/login')}
                                                >
                                                    Follow
                                                </button>
                                            )
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ClubFollowersPage;
