import { db } from '../firebase';
import { 
    collection, 
    addDoc, 
    deleteDoc, 
    query, 
    where, 
    getDocs,
    serverTimestamp,
    writeBatch,
    doc,
    increment
} from 'firebase/firestore';

// Follow a user
export const followUser = async (followerId, followingId) => {
    try {
        const batch = writeBatch(db);
        
        // Add follow record (similar to before)
        const followRef = doc(collection(db, 'follows'));
        batch.set(followRef, {
            followerId,
            followingId,
            timestamp: serverTimestamp()
        });

        // Add these new counter updates
        const followerRef = doc(db, 'users', followerId);
        batch.update(followerRef, {
            'userProfile.followingCount': increment(1)
        });
        
        const followingRef = doc(db, 'users', followingId);
        batch.update(followingRef, {
            'userProfile.followerCount': increment(1)
        });
        
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error following user:', error);
        throw error;
    }
};

// Unfollow a user
export const unfollowUser = async (followerId, followingId) => {
    try {
        // First find the follow document (similar to before)
        const q = query(
            collection(db, 'follows'),
            where('followerId', '==', followerId),
            where('followingId', '==', followingId)
        );
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) return false;
        
        const batch = writeBatch(db);
        
        // Delete the follow record
        const followDoc = querySnapshot.docs[0];
        batch.delete(followDoc.ref);
        
        // Decrement following count for follower
        const followerRef = doc(db, 'users', followerId);
        batch.update(followerRef, {
            'userProfile.followingCount': increment(-1)
        });
        
        // Decrement follower count for the one being unfollowed
        const followingRef = doc(db, 'users', followingId);
        batch.update(followingRef, {
            'userProfile.followerCount': increment(-1)
        });
        
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error unfollowing user:', error);
        throw error;
    }
};

// Check if user is following another user
export const isFollowing = async (followerId, followingId) => {
    try {
        const q = query(
            collection(db, 'follows'),
            where('followerId', '==', followerId),
            where('followingId', '==', followingId)
        );
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    } catch (error) {
        console.error('Error checking follow status:', error);
        throw error;
    }
};
