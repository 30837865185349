import { collection, query, where, getDocs, addDoc, deleteDoc, doc, writeBatch, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

export const checkIsFollowingClub = async (userId, clubId) => {
    const q = query(
        collection(db, 'clubFollows'),
        where('followerId', '==', userId),
        where('clubId', '==', clubId)
    );
    
    const snapshot = await getDocs(q);
    return !snapshot.empty;
};

export const followClub = async (userId, clubId) => {
    try {
        const batch = writeBatch(db);
        
        // Add follow record
        const followRef = doc(collection(db, 'clubFollows'));
        batch.set(followRef, {
            followerId: userId,
            clubId: clubId,
            createdAt: serverTimestamp()
        });
        
        // Increment club's follower count
        const clubRef = doc(db, 'clubs', clubId);
        batch.update(clubRef, {
            followerCount: increment(1)
        });
        
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error following club:', error);
        throw error;
    }
};

export const unfollowClub = async (userId, clubId) => {
    try {
        const batch = writeBatch(db);
        
        // Find and delete follow record
        const q = query(
            collection(db, 'clubFollows'),
            where('followerId', '==', userId),
            where('clubId', '==', clubId)
        );
        
        const snapshot = await getDocs(q);
        if (snapshot.empty) return false;
        
        const followDoc = snapshot.docs[0];
        batch.delete(followDoc.ref);
        
        // Decrement club's follower count
        const clubRef = doc(db, 'clubs', clubId);
        batch.update(clubRef, {
            followerCount: increment(-1)
        });
        
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error unfollowing club:', error);
        throw error;
    }
};

export const getClubFollowerCount = async (clubId) => {
    const q = query(
        collection(db, 'clubFollows'),
        where('clubId', '==', clubId)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.size;
};

// Add this new function to initialize follower counts
export const initializeClubFollowerCounts = async () => {
    const clubsSnapshot = await getDocs(collection(db, 'clubs'));
    
    for (const clubDoc of clubsSnapshot.docs) {
        const followersQuery = query(
            collection(db, 'clubFollows'),
            where('clubId', '==', clubDoc.id)
        );
        const followersSnapshot = await getDocs(followersQuery);
        
        const batch = writeBatch(db);
        batch.update(clubDoc.ref, {
            followerCount: followersSnapshot.size
        });
        await batch.commit();
    }
};
