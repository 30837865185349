import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, increment, orderBy, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CreatePost from '../components/CreatePost';
import './CommunityPage.css';
import { useAuth } from '../AuthContext';
import { formatDistanceToNow } from 'date-fns';
import AboutCommunity from '../components/AboutCommunity';
import PostVote from '../components/PostVote';
import ReportModal from '../components/ReportModal';
import DeleteModal from '../components/DeleteModal';
import RichTextEditor from '../components/RichTextEditor';
import '../styles/SharedStyles.css';

const CommunityPage = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();
    const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media";
    const [isMember, setIsMember] = useState(false);
    const [memberCount, setMemberCount] = useState(0);
    const { communityName } = useParams();
    const [community, setCommunity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [posts, setPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [showReportModal, setShowReportModal] = useState(false);
    const [communityData, setCommunityData] = useState(null);

    useEffect(() => {
        const fetchCommunityData = async () => {
            try {
                const communityDoc = await getDoc(doc(db, 'communities', communityName.toLowerCase()));
                if (communityDoc.exists()) {
                    setCommunityData({ id: communityDoc.id, ...communityDoc.data() });
                }
            } catch (error) {
                console.error('Error fetching community data:', error);
            }
        };

        fetchCommunityData();
    }, [communityName]);

    useEffect(() => {
        const fetchCommunity = async () => {
            try {
                const q = query(
                    collection(db, 'communities'),
                    where('nameToLower', '==', communityName.toLowerCase())
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const communityDoc = querySnapshot.docs[0];
                    const communityData = {
                        id: communityDoc.id,
                        ...communityDoc.data()
                    };
                    
                    console.log('Fetched community data:', communityData);
                    
                    // Fetch creator's username
                    if (communityData.createdBy) {
                        const creatorDoc = await getDoc(doc(db, 'users', communityData.createdBy));
                        if (creatorDoc.exists()) {
                            const creatorData = creatorDoc.data();
                            communityData.creatorUsername = creatorData.userProfile.username;
                            communityData.creatorProfileImage = creatorData.userProfile.profileImageUrl;
                        }
                    }
                    
                    // Add moderator data fetch
                    if (communityData.moderators && communityData.moderators.length > 0) {
                        const moderatorDoc = await getDoc(doc(db, 'users', communityData.moderators[0]));
                        if (moderatorDoc.exists()) {
                            const moderatorData = moderatorDoc.data();
                            communityData.moderatorUsername = moderatorData.userProfile.username;
                            communityData.moderatorProfileImage = moderatorData.userProfile.profileImageUrl || defaultAvatar;
                        }
                    }
                    
                    setCommunity(communityData);
                    setIsMember(communityData.members.includes(user?.uid));
                    setMemberCount(communityData.members.length);
                } else {
                    setError('Community not found');
                }
            } catch (err) {
                console.error('Error loading community:', err);
                setError('Error loading community: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCommunity();
    }, [communityName, user]);

    useEffect(() => {
        const fetchPosts = async () => {
            if (!communityName) return;

            try {
                const q = query(
                    collection(db, 'communities', communityName.toLowerCase(), 'posts'),
                    orderBy('createdAt', 'desc')
                );
                const querySnapshot = await getDocs(q);
                const postsList = await Promise.all(querySnapshot.docs.map(async doc => {
                    const postData = doc.data();
                    const commentsSnapshot = await getDocs(collection(db, 'communities', communityName.toLowerCase(), 'posts', doc.id, 'comments'));
                    
                    // Create the slug from the post title if it doesn't exist
                    const slug = postData.slug || postData.title.toLowerCase()
                        .replace(/[^a-z0-9]+/g, '-')
                        .replace(/(^-|-$)/g, '');

                    return {
                        id: doc.id,
                        ...postData,
                        slug: slug,
                        createdAt: postData.createdAt?.toDate(),
                        commentCount: commentsSnapshot.size
                    };
                }));
                setPosts(postsList);
            } catch (err) {
                console.error('Error fetching posts:', err);
            } finally {
                setLoadingPosts(false);
            }
        };

        fetchPosts();
    }, [communityName]);

    const handleJoinCommunity = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', communityName.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            const communityDoc = querySnapshot.docs[0];

            await updateDoc(communityDoc.ref, {
                members: arrayUnion(user.uid),
                membersCount: increment(1)
            });

            setCommunity(prev => ({
                ...prev,
                members: [...(prev.members || []), user.uid],
                membersCount: (prev.membersCount || 0) + 1
            }));
            setIsMember(true);
            setMemberCount(prev => prev + 1);
        } catch (error) {
            console.error('Error joining community:', error);
        }
    };

    const handleLeaveCommunity = async () => {
        try {
            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', communityName.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            const communityDoc = querySnapshot.docs[0];

            await updateDoc(communityDoc.ref, {
                members: arrayRemove(user.uid),
                membersCount: increment(-1)
            });

            setCommunity(prev => ({
                ...prev,
                members: prev.members.filter(id => id !== user.uid),
                membersCount: prev.membersCount - 1
            }));
            setIsMember(false);
            setMemberCount(prev => prev - 1);
        } catch (error) {
            console.error('Error leaving community:', error);
        }
    };

    const handlePostCreated = (newPost) => {
        setPosts(prevPosts => [newPost, ...prevPosts]);
    };

    const Post = ({ post }) => {
        const [authorUsername, setAuthorUsername] = useState('');
        const [authorProfilePhoto, setAuthorProfilePhoto] = useState(defaultAvatar);
        const [showShareMenu, setShowShareMenu] = useState(false);
        const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
        const shareMenuRef = useRef(null);
        const [showReportModal, setShowReportModal] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false);
        
        useEffect(() => {
            const fetchAuthor = async () => {
                try {
                    const userDoc = await getDoc(doc(db, 'users', post.authorId));
                    if (userDoc.exists()) {
                        const userData = userDoc.data().userProfile;
                        setAuthorUsername(userData.username);
                        setAuthorProfilePhoto(userData.profileImageUrl || defaultAvatar);
                    }
                } catch (error) {
                    console.error('Error fetching author:', error);
                    setAuthorProfilePhoto(defaultAvatar);
                }
            };
            fetchAuthor();
        }, [post.authorId]);

        const truncateText = (text, maxLength = 250) => {
            if (!text) return '';
            
            // If it's HTML content, handle it properly
            if (post.isHtml) {
                // Create a temporary div to handle HTML content
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = text;
                const textContent = tempDiv.textContent || tempDiv.innerText;
                
                if (textContent.length <= maxLength) {
                    // Return the original HTML if it's within length
                    return text;
                }
                // Truncate the text and maintain HTML
                return text.substr(0, maxLength) + '...';
            }
            
            // Handle plain text with line breaks
            const lines = text.split('\n');
            let currentLength = 0;
            let truncatedLines = [];
            
            for (const line of lines) {
                if (currentLength + line.length > maxLength) {
                    // If this line would exceed the limit, truncate it
                    const remainingLength = maxLength - currentLength;
                    if (remainingLength > 0) {
                        truncatedLines.push(line.substr(0, remainingLength) + '...');
                    }
                    break;
                }
                truncatedLines.push(line);
                currentLength += line.length + 1; // +1 for the newline character
            }
            
            return truncatedLines.join('\n');
        };

        const handleCopyLink = async () => {
            try {
                await navigator.clipboard.writeText(window.location.href);
                setShowCopiedTooltip(true);
                setTimeout(() => {
                    setShowCopiedTooltip(false);
                    setShowShareMenu(false);
                }, 2000);
            } catch (err) {
                console.error('Failed to copy:', err);
            }
        };

        const handleDeletePost = async () => {
            try {
                const postRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', post.id);
                await updateDoc(postRef, {
                    isDeleted: true
                });
                setShowDeleteModal(false);
                setPosts(prevPosts => 
                    prevPosts.map(p => 
                        p.id === post.id ? { ...p, isDeleted: true } : p
                    )
                );
            } catch (error) {
                console.error('Error deleting post:', error);
            }
        };

        return (
            <div className="post">
                <div className="post-header">
                    <Link to={`/user/${authorUsername}`} className="profile-photo-link">
                        <img 
                            src={authorProfilePhoto || defaultAvatar} 
                            alt="Profile" 
                            className="profile-photo"
                            onError={(e) => {
                                e.target.src = defaultAvatar;
                            }}
                        />
                    </Link>
                    <div className="post-meta">
                        <Link to={`/user/${authorUsername}`} className="username-link">
                            {authorUsername}
                        </Link>
                        <span className="post-meta-separator">•</span>
                        <span className="post-date">
                            {formatDistanceToNow(post.createdAt)} ago
                        </span>
                    </div>
                </div>
                
                <div className="post-content">
                    <Link to={`/c/${communityName}/posts/${post.id}/${post.slug}`} className="post-title-link">
                        <h3 className="post-title">{post.title}</h3>
                    </Link>
                    <div className="post-text">
                        {post.isDeleted ? (
                            <p className="deleted-message">Sorry, this post was deleted by the user.</p>
                        ) : (
                            <div className="truncated-content">
                                <div 
                                    className="preserve-whitespace"
                                    dangerouslySetInnerHTML={{ 
                                        __html: truncateText(post.content)
                                    }}
                                />
                                {post.content.length > 250 && (
                                    <Link 
                                        to={`/c/${communityName}/posts/${post.id}/${post.slug}`}
                                        className="read-more"
                                    >
                                        Read more
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                
                <div className="post-actions">
                    <div className="vote-section">
                        <PostVote 
                            postId={post.id}
                            communityName={communityName}
                            initialVoteCount={post.voteCount || 0}
                            onVoteChange={(newCount) => {
                                setPosts(prevPosts => 
                                    prevPosts.map(p => 
                                        p.id === post.id 
                                            ? { ...p, voteCount: newCount }
                                            : p
                                    )
                                );
                            }}
                        />
                    </div>
                    <Link to={`/c/${communityName}/posts/${post.id}/${post.slug}`} className="action-button">
                        <i className="far fa-comment"></i>
                        {post.commentCount === 1 
                            ? '1 Comment' 
                            : `${post.commentCount || 0} Comments`
                        }
                    </Link>
                    <div className="share-button-container" ref={shareMenuRef}>
                        <button 
                            className="action-button"
                            onClick={() => setShowShareMenu(!showShareMenu)}
                        >
                            <i className="far fa-share-square"></i>
                            Share
                        </button>
                        {showShareMenu && (
                            <div className="share-menu">
                                <button 
                                    className="share-menu-item"
                                    onClick={handleCopyLink}
                                >
                                    {showCopiedTooltip ? (
                                        <>
                                            <i className="fas fa-check"></i>
                                            Copied!
                                        </>
                                    ) : (
                                        <>
                                            <i className="far fa-copy"></i>
                                            Copy link
                                        </>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    <button 
                        className="action-button"
                        onClick={() => setShowReportModal(true)}
                    >
                        <i className="fas fa-flag"></i>
                        Report
                    </button>
                    
                    {isAuthenticated && user?.uid === post.authorId && !post.isDeleted && (
                        <>
                            <Link 
                                to={`/c/${communityName}/posts/${post.id}/${post.slug}`}
                                className="action-button"
                                state={{ isEditing: true }}
                            >
                                <i className="far fa-edit"></i>
                                Edit
                            </Link>
                            <button 
                                className="action-button"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <i className="far fa-trash-alt"></i>
                                Delete
                            </button>
                        </>
                    )}
                    
                    <ReportModal
                        isOpen={showReportModal}
                        onClose={() => setShowReportModal(false)}
                        contentType="post"
                        contentData={{
                            postId: post.id,
                            communityName,
                            authorId: post.authorId,
                            postUrl: window.location.href,
                            postContent: {
                                title: post.title,
                                content: post.content
                            }
                        }}
                    />
                    
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={handleDeletePost}
                    />
                </div>
            </div>
        );
    };

    const renderCommunityReport = () => {
        if (!isAuthenticated || !communityData) return null;
        
        return (
            <>
                <button 
                    className="community-report-button"
                    onClick={() => setShowReportModal(true)}
                >
                    <i className="fas fa-flag"></i>
                    Report Community
                </button>
                
                <ReportModal
                    isOpen={showReportModal}
                    onClose={() => setShowReportModal(false)}
                    contentType="community"
                    contentData={{
                        communityName,
                        communityId: communityData.id,
                        creatorId: communityData.createdBy,
                        description: communityData.description,
                        communityUrl: window.location.href
                    }}
                />
            </>
        );
    };

    if (loading) {
        return (
            <>
                <Header />
                <main>
                    <div className="page-container">
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading community...</div>
                        </div>
                    </div>
                </main>
                <Footer />
            </>
        );
    }

    if (error) {
        return (
            <>
                <Header />
                <main>
                    <div className="page-container">
                        <div className="error-message">{error}</div>
                    </div>
                </main>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="page-header">
                        <div className="title-section">
                            <h1>{community.name}</h1>
                            <div className="username-display">r/{communityName}</div>
                        </div>
                        <div className="action-buttons">
                            <Link to={`/c/${communityName}/post`} className="button-primary">
                                Create Post
                            </Link>
                            {community.moderators?.includes(user?.uid) && (
                                <Link to={`/c/${communityName}/edit`} className="button-secondary">
                                    Manage Community
                                </Link>
                            )}
                            <button 
                                className={isMember ? "button-secondary" : "button-primary"}
                                onClick={isMember ? handleLeaveCommunity : handleJoinCommunity}
                            >
                                {isMember ? 'Leave' : 'Join'}
                            </button>
                        </div>
                    </div>

                    <div className="content-container">
                        <div className="posts-section">
                            {loadingPosts ? (
                                <div className="loading-spinner" />
                            ) : posts.length > 0 ? (
                                posts.map(post => (
                                    <Post key={post.id} post={post} />
                                ))
                            ) : (
                                <div className="no-posts">
                                    <h3>This community doesn't have any posts yet</h3>
                                    <p>Make one and get this feed started.</p>
                                    <Link to={`/c/${communityName}/post`} className="button-primary">
                                        Create Post
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div className="sidebar">
                            <AboutCommunity community={community} />
                            {renderCommunityReport()}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default CommunityPage;
